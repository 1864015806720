<template>
    <div>
        <sub-header :backButton="true"></sub-header>
        <div class="grid gap-7 mt-2">
            <!-- Create Usage start  -->
            <div class="shadow-md relative rounded-2xl bg-white flex gap-6 flex-col">
                <div class="p-3 panel-head bg-primary-300 font-bold text-base-content flex gap-3 justify-between items-center rounded-t-2xl">
                    Modify Source Usage 
                </div>
                <div class="bg-white p-6 rounded-lg py-24 flex items-center justify-center" v-if="loadingState.details">
                    <Loader />
                </div>
                <FormulateForm v-else>
                    <div class="grid grid-cols-4 gap-x-6 p-2 ml-2">
                        <div>
                            <h2 class="mb-2 mt-1">User Name <span class="text-red-500">*</span></h2>
                            <Dropdown placeholder="User Name" v-model="sourceDetails.user_name" disabled
                                class="disableDropdown"
                                :colors="{
                                    text: '#282828',
                                    // bg: 'white',
                                    svg: '#282828',
                                    border: '#bcbcbc',
                                }" :options="userNameList" @input="handleUserSelect(sourceDetails.user_name)"
                                :config="{ label: 'user_name', trackBy: 'user_name' }" maxWidth="100%" minWidth="100%" width="102px"
                            />
                        </div>
                        <div>
                            <h2 class="mb-2 mt-1">Source <span class="text-red-500">*</span></h2>
                            <Dropdown placeholder="Source Name"  v-model="sourceDetails.credit_source_name" style="font-weight: 300;"
                            class="disableDropdown"
                                :colors="{
                                    text: '#282828',
                                    bg: 'white',
                                    svg: '#282828',
                                    border: '#bcbcbc',
                                }"
                                :options="sourceNameList" @input="handleSourceSelect(sourceDetails.credit_source_name)" disabled
                                :config="{ label: 'credit_source_name', trackBy: 'credit_source_name' }" maxWidth="100%" minWidth="100%" width="102px"
                            />
                        </div>
                        <FormulateInput class="w-full" label="Max Queries/Month" placeholder="max query per month" type="text" v-model="sourceDetails.max_queries_per_month"
                            validation="required" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                        />
                        <FormulateInput class="w-full" label="Max Queries/Day" placeholder="max query per day" type="text" v-model="sourceDetails.max_queries_per_day"
                            validation="required" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                        />
                        <div class="my-4">
                            <h2>Actions</h2>
                            <div class="flex gap-7">
                                <div class="flex gap-2">
                                    <input type="checkbox" class="checkbox mt-1 checkbox-sm" value="1" v-model="status.activated"
                                        @change="handleStatusClick(true)"
                                    />
                                    <label class="cursor-pointer label">
                                        <span class="label-text">Activate</span>
                                    </label>
                                </div>
                                <div class="flex gap-2">
                                    <input type="checkbox" class="checkbox mt-1 checkbox-sm" value="0" v-model="status.deactivated"
                                        @change="handleStatusClick(false)"
                                    />
                                    <label class="cursor-pointer label">
                                        <span class="label-text">Deactivate</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </FormulateForm>
            </div>
            <!-- Create Usage ends -->
            
        </div>
        <div class="flex gap-3 float-right" >
            <Button :loader="loadingState.create" text="update" class="mt-2" type="primary" @click="update()" />
        </div>
    </div>
</template>
<script>
    import SubHeader from "@/components/SubHeader";
    import Dropdown from "@shared/components/dropdown-base";
    import Button from "@shared/components/button";
    import { getUsageDetails, updateUsageDetails } from "../services";
    import Loader from "@shared/loader";
    export default {
        data: () => {
            return {
                loadingState: {
                    details: false,
                    create: false,
                },
                sourceDetails: {
                    user_id: null,
                    credit_source_id: null,
                    max_queries_per_month: null,
                    max_queries_per_day: null,
                    credit_source_name: null,
                    user_name: null,
                },
                status: {
                    activated: false,
                    deactivated: false,
                },
            
            };
        },
        computed: {
        
        },
        async mounted() {
            this.loadingState.details = true;
            await this.setSourceUsageDetails()
            this.loadingState.details = false;

        },
        components: {
            SubHeader,
            Dropdown,
            Loader,
            Button,
        },
        methods: {
            async setSourceUsageDetails(){
                const { data } = await getUsageDetails(this.$route.params.id)
                this.sourceDetails = data.source_usage
                if (this.sourceDetails.active) {
                    this.status.activated = true
                }
                else {
                    this.status.deactivated = true
                }
            },
            handleStatusClick(active) {
                if (active) {
                    this.status.deactivated = false;
                } else {
                    this.status.activated = false;
                }
            },
            async update(){
                if (!this.status.activated && !this.status.deactivated) {
                    this.$toast.error("Please select action");
                    return;
                }
                try{
                    let payload = {
                        user_id: this.sourceDetails.user_id,
                        credit_source_id: this.sourceDetails.credit_source_id,
                        max_queries_per_day: this.sourceDetails.max_queries_per_day,
                        max_queries_per_month: this.sourceDetails.max_queries_per_month,
                        active: this.status.activated ? true : false
                    }
                    await updateUsageDetails(this.$route.params.id, payload)
                    this.$toast.success("Update Successfully")
                }
                catch(error){
                    this.$toast.error('Something went wrong')
                }           
            }
        
        },
        
    };
</script>
<style scoped lang="scss">
::v-deep {
  .disableInput {
    input {
      background: #f9fafb !important;
      cursor: not-allowed;
    }
  }
  .disableDropdown {
    background: #f9fafb !important;
    cursor: not-allowed;
  }
}
</style>
  