<template>
  <div>
    <sub-header :backButton="true"></sub-header>
    <div class="grid gap-7 mt-2">
      <!-- Create Usage start  -->
      <div class="shadow-md relative rounded-2xl bg-white flex gap-6 flex-col">
        <div
          class="p-3 panel-head bg-primary-300 font-bold text-base-content flex gap-3 justify-between items-center rounded-t-2xl"
        >
          Create Usage Control
        </div>
        <div
          class="bg-white p-6 rounded-lg py-24 flex items-center justify-center"
          v-if="loadingState.details"
        >
          <Loader />
        </div>
        <FormulateForm v-else>
          <div class="grid grid-cols-4 gap-x-6 p-2 ml-2">
            <div>
              <h2 class="mb-2 mt-1">
                User Name <span class="text-red-500">*</span>
              </h2>
              <Dropdown
                placeholder="User Name"
                v-model="sourceDetails.user_name"
                :colors="{
                  text: '#282828',
                  bg: 'white',
                  svg: '#282828',
                  border: '#bcbcbc',
                }"
                :options="userNameList"
                @input="handleUserSelect(sourceDetails.user_name)"
                :config="{ label: 'user_name', trackBy: 'user_name' }"
                maxWidth="100%"
                minWidth="100%"
                width="102px"
              />
            </div>
            <div>
              <h2 class="mb-2 mt-1">
                Source <span class="text-red-500">*</span>
              </h2>
              <Dropdown
                placeholder="Source Name"
                v-model="sourceDetails.credit_source_name"
                style="font-weight: 300"
                :colors="{
                  text: '#282828',
                  bg: 'white',
                  svg: '#282828',
                  border: '#bcbcbc',
                }"
                :options="sourceNameList"
                @input="handleSourceSelect(sourceDetails.credit_source_name)"
                :config="{
                  label: 'credit_source_name',
                  trackBy: 'credit_source_name',
                }"
                maxWidth="100%"
                minWidth="100%"
                width="102px"
              />
            </div>
            <FormulateInput
              class="w-full"
              label="Max Queries/Month"
              placeholder="max query per month"
              type="text"
              v-model="sourceDetails.max_query_per_month"
              validation="required"
              :element-class="
                (context, classes) => ['flex-1 min-w-full'].concat(classes)
              "
            />
            <FormulateInput
              class="w-full"
              label="Max Queries/Day"
              placeholder="max query per day"
              type="text"
              v-model="sourceDetails.max_query_per_day"
              validation="required"
              :element-class="
                (context, classes) => ['flex-1 min-w-full'].concat(classes)
              "
            />
          </div>
        </FormulateForm>
      </div>
      <!-- Create Usage ends -->
    </div>
    <div class="flex gap-3 float-right">
      <Button
        :loader="loadingState.create"
        text="Create"
        class="mt-2"
        type="primary"
        @click="create()"
      />
    </div>
  </div>
</template>
<script>
import SubHeader from "@/components/SubHeader";
import Dropdown from "@shared/components/dropdown-base";
import Button from "@shared/components/button";
import { createUsage, getUsers, getCreditSourceList } from "../services";
import Loader from "@shared/loader";
export default {
  data: () => {
    return {
      loadingState: {
        details: false,
        create: false,
      },
      sourceDetails: {
        user_id: null,
        credit_source_id: null,
        max_query_per_month: null,
        max_query_per_day: null,
        credit_source_name: null,
        user_name: null,
      },
      userNameList: [],
      sourceNameList: [],
    };
  },
  computed: {},
  async mounted() {
    this.loadingState.details = true;
    await this.setSourceUsage();
    this.loadingState.details = false;
  },
  components: {
    SubHeader,
    Dropdown,
    Loader,
    Button,
  },
  methods: {
    async setSourceUsage() {
      let client_id = this.$store.getters.getClientId;
      let userlist = await getUsers(client_id);
      const clientKeys = ["id", "user_name"];
      userlist = userlist.data.map((obj) => {
        const newObj = {};
        clientKeys.forEach((res) => {
          newObj[res] = obj[res];
        });
        return newObj;
      });
      this.userNameList = userlist;
      let sourcelist = await getCreditSourceList();
      const sourceKeys = ["id", "credit_source_name"];
      sourcelist = sourcelist.data.data.map((obj) => {
        const newObj = {};
        sourceKeys.forEach((res) => {
          newObj[res] = obj[res];
        });
        return newObj;
      });
      this.sourceNameList = sourcelist;
    },
    handleSourceSelect(value) {
      this.sourceDetails.credit_source_id = value.id;
    },
    handleUserSelect(value) {
      this.sourceDetails.user_id = value.id;
    },
    async create() {
      try {
        let payload = {
          user_id: this.sourceDetails.user_id,
          credit_source_id: this.sourceDetails.credit_source_id,
          max_queries_per_day: parseInt(this.sourceDetails.max_query_per_day),
          max_queries_per_month: parseInt(
            this.sourceDetails.max_query_per_month
          ),
        };
        let { data } = await createUsage(payload);
        this.$toast.success(data?.message);
        this.$router.push({
          path: "/credit-packs?tab=sourceUsage",    
        });
      } catch (error) {
        if (Array.isArray(error?.response?.data?.detail)) {
          this.$toast.error(error?.response?.data?.detail?.[0]?.msg);
        } else {
          this.$toast.error(error?.response?.data?.detail);
        }
      }
    },
  },
};
</script>
