<template>
    <div>
        <sub-header :backButton="true"></sub-header>
        <div class="grid gap-7 mt-2">
            <!-- Credits  Deatils Start-->
            <div class="shadow-md relative rounded-2xl bg-white flex gap-6 flex-col">
                <div class="p-3 panel-head bg-primary-300 font-bold text-base-content flex gap-3 justify-between items-center rounded-t-2xl">
                    Credits Pack Details
                </div>
                <div class="bg-white p-6 rounded-lg py-24 flex items-center justify-center" v-if="loadingState">
                    <Loader />
                </div>
                <FormulateForm v-else>
                    <div class="grid grid-cols-3 gap-x-6 p-2 ml-2">
                        <FormulateInput class="w-full disableInput" label="Pack Name *" placeholder="Pack Name" type="text" v-model="creditDetails.name"
                            :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" disabled
                        />
                        <div>
                            <h1 class="font-normal label items-center capitalize max-w-full">Validity in Days </h1>
                            <label class="input-group">
                                <span class="bg-primary-300 ">Days</span>
                                <input type="number" placeholder="validity in days" class="input input-bordered input-sm w-full" disabled v-model="creditDetails.validity"/>
                            </label>
                        </div>
                        <div>
                            <h1 class="font-normal label items-center capitalize max-w-full">Discount </h1>
                            <label class="input-group">
                                <span class="bg-primary-300 ">%</span>
                                <input type="number" placeholder="Discount" class="input input-bordered input-sm w-full" disabled v-model="creditDetails.discount"/>
                            </label>
                        </div>
                        <FormulateInput class="w-full disableInput" label="Credits" placeholder="Credits" type="text" v-model="creditDetails.pack_credits"
                            :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" disabled
                        />
                        <div>
                            <h1 class="font-normal label items-center capitalize max-w-full">List Price</h1>
                            <label class="input-group">
                                <span class="bg-primary-300 ">{{ creditDetails.currency_symbol }}</span>
                                <input type="number" placeholder="days" class="input input-bordered input-sm w-full" disabled v-model="creditDetails.list_price"/>
                            </label>
                        </div>
                        <div>
                            <h1 class="font-normal label items-center capitalize max-w-full">Discounted Price </h1>
                            <label class="input-group">
                                <span class="bg-primary-300 ">{{ creditDetails.currency_symbol }}</span>
                                <input type="number" placeholder="days" class="input input-bordered input-sm w-full" disabled v-model="creditDetails.discounted_price"/>
                            </label>
                        </div>
                    </div>
                </FormulateForm>
            </div>
            <!-- Credit Details ends -->
        </div>
    </div>
</template>
<script>
    import SubHeader from "@/components/SubHeader";
    import { getCreditPackDeatails} from "../services";
    import Loader from "@shared/loader";
    export default {
        data: () => {
            return {
                loadingState: false,
                creditDetails: {
                    name: null,
                    validity: null,
                    pack_credits: null,
                    discount: null,
                    discounted_price:null,
                    list_price: null,
                    currency_symbol:null,
                },
            };
        },
        async mounted() {
            this.loadingState = true;
                await this.setCreditPacks()
            this.loadingState = false;

        },
        components: {
            SubHeader,
            Loader,
        },
        methods: {
            async setCreditPacks(){
                const { data } = await getCreditPackDeatails(this.$route.params.id);
                this.creditDetails =  data
            },
        },
        
    };
</script>
<style scoped lang="scss">
    .input-sm{
        height: 2.4rem;
    }
    ::v-deep {
    .disableInput {
        input {
            background: #f9fafb !important;
            cursor: not-allowed;
        }
    }
}
</style>
  