<template>
  <div>
    <sub-header :backButton="true"></sub-header>
    <div class="grid gap-7 mt-2">
      <!-- Allocated Credits  -->
      <div class="shadow-md relative rounded-2xl bg-white flex gap-6 flex-col">
        <div
          class="p-3 panel-head bg-primary-300 font-bold text-base-content flex gap-3 justify-between items-center rounded-t-2xl"
        >
          Allocated Credits To User
        </div>
        <div
          class="bg-white p-6 rounded-lg py-24 flex items-center justify-center"
          v-if="loadingState.creditList"
        >
          <Loader />
        </div>
        <FormulateForm v-else>
          <div class="grid grid-cols-4 gap-x-6 p-2 ml-2">
            <div>
              <h2 class="mb-2 mt-1">
                Pack Unique Id <span class="text-red-500">*</span>
              </h2>
              <Dropdown
                placeholder="Select Unique Id"
                v-model="orderDetails.unique_id"
                :options="userIdList"
                :colors="{
                  text: '#282828',
                  bg: 'white',
                  svg: '#282828',
                  border: '#bcbcbc',
                }"
                @input="handlePackSelect(orderDetails.unique_id)"
                :config="{ label: 'unique_id', trackBy: 'unique_id' }"
                maxWidth="100%"
                minWidth="100%"
                width="102px"
              />
            </div>
            <FormulateInput
                class="w-full disableInput"
                label="Credit Pack Name"
                placeholder="Credits"
                type="text"
                v-model="orderDetails.credit_pack_name"
                :element-class="
                  (context, classes) => ['flex-1 min-w-full'].concat(classes)
                "
                disabled
              />
            <div>
              <h2 class="mb-2 mt-1">
                Allotment Type <span class="text-red-500">*</span>
              </h2>
              <Dropdown
                placeholder="Allotment Type"
                v-model="orderDetails.pack_type"
                style="font-weight: 300"
                :disabled="orderDetails?.unique_id ? false : true"
                :colors="{
                  text: '#282828',
                  bg: 'white',
                  svg: '#282828',
                  border: '#bcbcbc',
                }"
                :options="allotmentTypes"
                @input="handleTypeSelect(orderDetails.pack_type)"
                :config="{ label: 'name', trackBy: 'name' }"
                maxWidth="100%"
                minWidth="100%"
                width="102px"
              />
            </div>
            <FormulateInput
              class="w-full disableInput"
              label="Credits "
              placeholder="Credits"
              type="text"
              v-model="orderDetails.total_credits"
              :element-class="
                (context, classes) => ['flex-1 min-w-full'].concat(classes)
              "
              disabled
            />
            <FormulateInput
              v-if="loadingState.allotedTypeShared"
              class="w-full disableInput"
              label="Credits Balance"
              placeholder="Credits Balance"
              type="text"
              v-model="orderDetails.remaining_credits"
              :element-class="
                (context, classes) => ['flex-1 min-w-full'].concat(classes)
              "
              disabled
            />
            <FormulateInput
              class="w-full disableInput"
              label="Pack Validity"
              placeholder="Pack Validity"
              type="text"
              v-model="orderDetails.validity"
              :element-class="
                (context, classes) => ['flex-1 min-w-full'].concat(classes)
              "
              disabled
            />
          </div>
          <div
            v-if="loadingState.allotedTypeIndiv && orderDetails?.unique_id"
            class="grid grid-cols-4 gap-x-6 p-2 ml-2"
          >
            <div>
              <h2 class="mb-2 mt-1">
                User Name <span class="text-red-500">*</span>
              </h2>
              <Dropdown
                placeholder="User Name"
                v-model="orderDetails.user_name"
                style="font-weight: 300"
                :colors="{
                  text: '#282828',
                  bg: 'white',
                  svg: '#282828',
                  border: '#bcbcbc',
                }"
                :options="userNameList"
                @input="handleUserNameSelect(orderDetails.user_name)"
                :config="{ label: 'user_name', trackBy: 'user_name' }"
                maxWidth="100%"
                minWidth="100%"
                width="102px"
              />
            </div>
            <FormulateInput
              class="w-full disableInput"
              label="Issued Credits"
              placeholder="Issued Credits"
              type="text"
              v-model="orderDetails.issued_credits"
              validation="required"
              :element-class="
                (context, classes) => ['flex-1 min-w-full'].concat(classes)
              "
              disabled
            />
            <FormulateInput
              class="w-full disableInput"
              label="Credits Balance"
              placeholder="Credits Balance"
              type="text"
              v-model="orderDetails.remaining_credits"
              :element-class="
                (context, classes) => ['flex-1 min-w-full'].concat(classes)
              "
              disabled
            />
            <FormulateInput
              class="w-full"
              label="Credits Limit/Month"
              placeholder="Credits Limit"
              type="text"
              v-model="orderDetails.credit_limit"
              validation="required"
              :element-class="
                (context, classes) => ['flex-1 min-w-full'].concat(classes)
              "
            />
          </div>
        </FormulateForm>
      </div>
      <!-- Allocated ends -->
      <!-- Pack Details -->
      <div
        class="shadow-md relative rounded-2xl bg-white flex gap-6 flex-col"
        v-if="loadingState.allotedTypeShared && orderDetails?.unique_id"
      >
        <div
          class="p-3 panel-head bg-primary-300 font-bold text-base-content flex gap-3 justify-between items-center rounded-t-2xl"
        >
          Pack Allocation
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            @click="addPack"
            class="cursor-pointer"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_1758_9116)">
              <path
                d="M3.41391 8.69432C3.03391 8.31088 3.03391 7.68963 3.41391 7.3065C3.59641 7.12275 3.84391 7.01931 4.10172 7.01931H11.8892C12.4267 7.00994 12.8698 7.4415 12.8792 7.98338C12.8886 8.52525 12.4605 8.97213 11.923 8.9815C11.9117 8.9815 11.9005 8.9815 11.8892 8.9815H4.10203C3.84391 8.98244 3.59609 8.879 3.41391 8.69432Z"
                fill="#0D69D5"
              />
              <path
                d="M7.31921 12.6994C7.13921 12.5125 7.03827 12.2584 7.03921 11.9937V4.00717C7.03014 3.45592 7.45077 3.00154 7.97921 2.99186C8.50764 2.98217 8.94327 3.42123 8.95264 3.97248C8.95264 3.98404 8.95264 3.99561 8.95264 4.00717V11.9937C8.95264 12.545 8.52421 12.9919 7.99577 12.9915C7.74202 12.9915 7.49889 12.8865 7.31952 12.6994H7.31921Z"
                fill="#0D69D5"
              />
              <path
                d="M11.614 16H10.5625C10.1693 16 9.85059 15.6812 9.85059 15.2881C9.85059 14.895 10.1693 14.5763 10.5625 14.5763H11.614C12.4012 14.5763 13.144 14.2669 13.7056 13.7053C14.2671 13.1438 14.5765 12.4009 14.5765 11.6138V4.38594C14.5765 3.59875 14.2671 2.85594 13.7056 2.29437C13.144 1.73281 12.4012 1.42344 11.614 1.42344H10.5625C10.1693 1.42344 9.85059 1.10469 9.85059 0.711563C9.85059 0.318438 10.169 0 10.5621 0H11.6137C14.0321 0 15.9996 1.9675 15.9996 4.38594V11.6138C15.9996 14.0322 14.0321 15.9997 11.6137 15.9997L11.614 16Z"
                fill="#0D69D5"
              />
              <path
                d="M5.43781 16H4.38625C1.9675 16 0 14.0325 0 11.6141V4.38594C0 1.9675 1.9675 0 4.38594 0H5.4375C5.83063 0 6.14937 0.31875 6.14937 0.711875C6.14937 1.105 5.83063 1.42375 5.4375 1.42375H4.38594C3.59875 1.42375 2.85594 1.73312 2.29437 2.29469C1.73281 2.85625 1.42344 3.59906 1.42344 4.38625V11.6141C1.42344 12.4012 1.73281 13.1441 2.29437 13.7056C2.85594 14.2672 3.59875 14.5766 4.38594 14.5766H5.4375C5.83063 14.5766 6.14937 14.8953 6.14937 15.2884C6.14937 15.6816 5.83063 16.0003 5.4375 16.0003L5.43781 16Z"
                fill="#0D69D5"
              />
            </g>
            <defs>
              <clipPath id="clip0_1758_9116">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div
          class="bg-white p-6 rounded-lg py-24 flex items-center justify-center"
          v-if="loadingState.packDetails"
        >
          <Loader />
        </div>
        <div v-else>
          <custom-table
            :columns="columns"
            :rows="rows"
            class="p-2"
            emptyTableMessage="No pack alloted to User."
          >
            <template v-slot:user_name="props">
              <div class="justify-center ml-3">
                <Dropdown
                  :disabled="disableMethod"
                  placeholder="User  Name"
                  searchable
                  :colors="{
                    text: '#282828',
                    bg: 'white',
                    svg: '#282828',
                    border: '#bcbcbc',
                  }"
                  v-model="props.item.user_name"
                  :options="userNameList"
                  @input="handleUserNameSelect(props.item.user_name)"
                  reposition
                  maxWidth="93%"
                  minWidth="93%"
                  width="102px"
                  :config="{ label: 'user_name', trackBy: 'user_name' }"
                />
              </div>
            </template>
            <template v-slot:limit="props">
              <div class="justify-center">
                <input
                  type="text"
                  class="input input-bordered input-sm w-24"
                  v-model="props.item.limit"
                />
              </div>
            </template>
            <template v-slot:issued_credits="props">
              <div class="justify-center">
                <input
                  type="text"
                  class="input input-bordered input-sm w-24"
                  v-model="props.item.issued_credits"
                  :disabled="props.item.unlimited_credit_limit"
                />
              </div>
            </template>
            <template v-slot:unlimited_credit_limit="props">
              <div class="flex gap-2 ml-4">
                <input
                  type="checkbox"
                  :checked="props.item.unlimited_credit_limit"
                  class="checkbox checkbox-sm checkbox-primary"
                  v-model="props.item.unlimited_credit_limit"
                  @change="handleUnlimitedClick(props.item)"
                />
                <span>Unlimited</span>
              </div>
            </template>
            <template v-slot:action="props" class="flex justify-center">
              <svg
                @click="removeRow(props.item)"
                class="justify-center cursor-pointer ml-16"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                v-if="!disableMethod"
              >
                <path
                  d="M3.62645 4.87203V13.2127C3.62645 14.1998 4.48363 15 5.54033 15H11.2824C12.3395 15 13.1967 14.1998 13.1967 13.2127V4.87203H3.62645ZM6.18072 13.1217C6.18072 13.3385 6.03751 13.514 5.86074 13.514H5.54034C5.36336 13.514 5.22016 13.3385 5.22016 13.1217L5.22016 6.4518C5.22016 6.24277 5.35451 6.07583 5.52225 6.06362H5.87823C6.04597 6.07583 6.18032 6.24278 6.18032 6.4518V13.1217H6.18072V13.1217ZM9.05172 13.1217C9.05172 13.3385 8.90853 13.514 8.73173 13.514H8.09117C7.9146 13.514 7.77119 13.3385 7.77119 13.1217V6.4518C7.77119 6.24277 7.90557 6.07583 8.07328 6.06362H8.74926C8.91721 6.07583 9.05134 6.24278 9.05134 6.4518V13.1217H9.05172V13.1217ZM11.6028 6.4518V13.1218C11.6028 13.3387 11.4596 13.5141 11.2824 13.5141H10.9622C10.7856 13.5141 10.6424 13.3387 10.6424 13.1218V6.4518C10.6424 6.24277 10.7766 6.07583 10.9445 6.06362H11.3003C11.4686 6.07583 11.6028 6.24277 11.6028 6.4518ZM13.1967 2.48925H3.62645C3.27408 2.48925 2.98828 2.75611 2.98828 3.08494V3.38279C2.98828 3.71199 3.27408 3.9785 3.62645 3.9785H13.1967C13.5488 3.9785 13.8346 3.71199 13.8346 3.38279V3.08494C13.8346 2.75611 13.5492 2.48925 13.1967 2.48925Z"
                  fill="#FF0000"
                />
                <path
                  d="M7.65891 1H9.27774C9.63032 1 9.91572 1.26687 9.91572 1.5957V1.89354C9.91572 2.22275 9.62992 2.48925 9.27774 2.48925H7.65891C7.30655 2.48925 7.02075 2.22275 7.02075 1.89354V1.5957C7.02075 1.26687 7.30655 1 7.65891 1Z"
                  fill="#FF0000"
                />
              </svg>
            </template>
          </custom-table>
        </div>
      </div>
    </div>
    <!--   Pack Allocation ends -->
    <div
      class="flex gap-3 float-right"
      v-if="this.orderDetails.credit_pack_id && this.orderDetails.pack_type"
    >
      <Button
        :loader="loadingState.update"
        text="Create"
        class="mt-2"
        type="primary"
        @click="createOrder()"
      />
    </div>
  </div>
</template>
<script>
import SubHeader from "@/components/SubHeader";
import customTable from "@shared/components/custom-table";
import Dropdown from "@shared/components/dropdown-base";
import Button from "@shared/components/button";
import {
  getCreditInventory,
  getUsers,
  createCreditInventory,
} from "../services";
import Loader from "@shared/loader";
export default {
  data: () => {
    return {
      loadingState: {
        creditList: false,
        packDetails: false,
        allotedTypeShared: false,
        allotedTypeIndiv: false,
      },
      orderDetails: {
        issued_credits: null,
        validity: null,
        total_credits: null,
        pack_type: null,
        unique_id: null,
        credit_pack_id: null,
        credit_pack_name: null,
        remaining_credits: null,
        credit_limit: null,
      },
      columns: [
        {
          name: "User Name",
          label: "user_name",
          type: "custom",
        },
        {
          name: "Credit Limit",
          label: "unlimited_credit_limit",
          type: "custom",
        },
        {
          name: "Issued Credits",
          label: "issued_credits",
          type: "custom",
        },
        {
          name: "Limit/Month",
          label: "limit",
          type: "custom",
        },
        {
          name: "Action",
          label: "action",
          type: "custom",
        },
      ],
      rows: [],
      allotmentTypes: [{ name: "Individual" }, { name: "Shared" }],
      userIdList: [],
      currentUserId: null,
      userNameList: [],
      user_id: [],
    };
  },
  computed: {},
  async mounted() {
    this.loadingState.creditList = true;
    await this.setUniquePack();
    this.loadingState.creditList = false;
  },
  components: {
    SubHeader,
    Dropdown,
    Loader,
    customTable,
    Button,
  },
  methods: {
    async setUniquePack() {
      let { data } = await getCreditInventory();
      const uniqueKey = [
        "credit_pack_id",
        "credit_pack_name",
        "unique_id",
        "total_credits",
        "validity",
        "remaining_credits",
      ];
      data = data.map((obj) => {
        const newObj = {};
        uniqueKey.forEach((res) => {
          newObj[res] = obj[res];
        });
        return newObj;
      });
      this.userIdList = data?.filter(
        (packData) => parseInt(packData?.remaining_credits) > 0
      );
      let client = this.$store.getters.getClientId;
      let userlist = await getUsers(client);
      const clientKeys = ["id", "user_name"];
      userlist = userlist.data.map((obj) => {
        const newObj = {};
        clientKeys.forEach((res) => {
          newObj[res] = obj[res];
        });
        return newObj;
      });
      this.userNameList = userlist;
    },
    handleUnlimitedClick(check) {
      if (check.unlimited_credit_limit) {
        check.issued_credits = this.orderDetails.total_credits;
      }
    },
    handlePackSelect(value) {
      if (value && this.orderDetails.credit_pack_id != value.credit_pack_id) {
        this.orderDetails.credit_pack_id = value.credit_pack_id;
        this.orderDetails.credit_pack_name = value.credit_pack_name;
        this.orderDetails.unique_id = value.unique_id;
        this.orderDetails.remaining_credits = value.remaining_credits;
        this.orderDetails.total_credits = value.total_credits;
        this.orderDetails.validity = value.validity;
        this.orderDetails.issued_credits = value.remaining_credits;
        this.orderDetails.credit_limit = value.remaining_credits;
        this.rows = [];
      } else if (!value || value == null) {
        this.orderDetails = {
          issued_credits: null,
          validity: null,
          total_credits: null,
          pack_type: null,
          unique_id: null,
          credit_pack_id: null,
          remaining_credits: null,
          credit_limit: null,
        };
        this.loadingState = {
          allotedTypeShared: false,
          allotedTypeIndiv: false,
        };
      }
    },
    handleTypeSelect(value) {
      if (value.name.toLowerCase() == "shared") {
        this.loadingState.allotedTypeShared = true;
        this.loadingState.allotedTypeIndiv = false;
      } else if (value.name.toLowerCase() == "individual") {
        this.loadingState.allotedTypeIndiv = true;
        this.loadingState.allotedTypeShared = false;
      } else {
        this.loadingState.allotedTypeIndiv = false;
        this.loadingState.allotedTypeShared = false;
      }
    },
    handleUserNameSelect(value) {
      this.user_id = value;
    },
    addPack() {
      this.rows.push({
        row_id: this.rows.length + 1,
        user_name: "",
        unlimited_credit_limit: true,
        issued_credits: this.orderDetails.total_credits,
        limit: this.orderDetails.total_credits,
        action: true,
      });
    },
    removeRow(value) {
      this.rows = this.rows.filter((res) => res.row_id !== value.row_id);
    },
    async createOrder() {
      if (!this.orderDetails.pack_type) {
        this.$toast.error("Please select the allotment type.");
        return;
      }

      try {
        let payload;
        if (this.orderDetails.pack_type.name.toLowerCase() == "individual") {
          if (!this.user_id.id) {
            this.$toast.error("Please select the user name.");
            return;
          }
          payload = {
            credit_pack_id: this.orderDetails.credit_pack_id,
            pack_type: this.orderDetails.pack_type.name,
            users: [
              {
                new_user_id: this.user_id.id,
                issued_credits: parseInt(this.orderDetails.issued_credits),
                limit: parseInt(this.orderDetails.credit_limit),
                unlimited_credit_limit: false,
              },
            ],
          };
        } else {
          if (!this.rows.length) {
            this.$toast.error("Please add user.");
            return;
          }
          let Users = [];
          let duplicateUsers = false;
          this.rows?.forEach((user) => {
            this.rows?.forEach((innerUser) => {
              if (
                innerUser?.user_name.id == user?.user_name?.id &&
                user?.row_id != innerUser?.row_id
              ) {
                duplicateUsers = true;
              }
            });
          });
          if (duplicateUsers) {
            this.$toast.error("Can not allocate to same user multiple times!");
            return;
          }
          this.rows.forEach((res) => {
            Users.push({
              new_user_id: res.user_name.id,
              issued_credits: res.issued_credits,
              limit: res.limit,
              unlimited_credit_limit: res.unlimited_credit_limit,
            });
          });
          payload = {
            credit_pack_id: this.orderDetails.credit_pack_id,
            pack_type: this.orderDetails.pack_type.name,
            users: Users,
          };
        }

        await createCreditInventory(payload);
        this.$toast.success("Credits Assigned Successfully");
      } catch (error) {
        this.$toast.error("Something went wrong");
      }
    },
  },
};
</script>
<style scoped lang="scss">
  ::v-deep {
  .disableInput {
    input {
      background: #f9fafb !important;
      cursor: not-allowed;
    }
  }
  .disableDropdown {
    background: #f9fafb !important;
    cursor: not-allowed;
  }
}
</style>
