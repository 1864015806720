<template>
    <div class="field-table flex-1 flex flex-col  max-h-full max-w-full  min-h-full text-base-content">
        <SubHeader :customCrumbLists="customCrumbLists"/>
        <!-- <div class="flex flex-1 flex-col flex-grow max-h-full max-w-full overflow-auto scroll-bar"> -->
            <vue-good-table 
                :columns="columns" 
                :isLoading="loading" 
                :rows="rowsData" 
                :pagination-options="{ enabled: true, mode: 'remote', dropdownAllowAll: false}"
                :totalRows="pageData.total_count"
                @on-page-change="onPageChange($event)"
                @on-per-page-change="onPageChange($event, true)"
                :per-page-options="customPerPageOptions"
            >
                <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field == 'credit_source_name'" class="flex justify-center" >
                        {{props.row.credit_source_name}}
                    </div>
                    <div v-if="props.column.field === 'sub_feature_name'" class='' :title="props.row.sub_feature_name">
                        {{ getNrnName(props.row.sub_feature_name) }}
                    </div>
                    <div v-if="props.column.field === 'output'" class='' :title="props.row.output">
                        {{ getNrnName(props.row.output) }}
                    </div>
                    <div v-if="props.column.field == 'credit_per_hit'" class="flex justify-center" :class="{'text-primary' : checkCustomData(props.row)}">
                        {{ showCreditHit(props.row) }}
                    </div>
                    <div v-if="props.column.field == 'service_charge_per_hit'" class="flex justify-center" :class="{'text-primary' : checkCustomData(props.row)}">
                        {{ showServiceCharge(props.row) }}
                    </div>
                </template>

                <template #loadingContent>
                    <div class="w-full py-10 justify-center flex">
                        <Loader />
                    </div>
                </template>
            </vue-good-table>
        <!-- </div> -->
    </div>
</template>
<script>
    import SubHeader from "@/components/SubHeader";
    import { creditAndSourceList } from "../services"
    import Loader from "@/components/loader";

    export default {
        name: "credit-packs",
        title: "Credit Admin",
        components: {
            SubHeader,
            Loader,
        },
        data() {
            return {
                columns: [{
                    label: "Source",
                    field: "credit_source_name",
                    sortable: true
                },{
                    label: "Input",
                    field: "sub_feature_name",
                    sortable: true
                },{
                    label: "Output",
                    field: "output",
                    sortable: true
                },{
                    label: "Credits/Hits",
                    field: "credit_per_hit",
                    sortable: true
                },{
                    label: "Service Charge/Hits",
                    field: "service_charge_per_hit",
                    sortable: true
                } ],
                rowsData: [],
                pageData: {
                    total: 0,
                },
                currentPageData: {
                    page: 1,
                    limit: 10,
                },
                customPerPageOptions: [10, 20, 30, 40, 50],
                loading: false,
                customCrumbLists: [{name: 'Credit & Source'}],
                
            }
        },
        async mounted() {
            this.loading = true
            await this.setCreditSourceList()
            this.loading = false

        },
        methods: {
            getNrnName(value) {
                if (value?.length > 90) {
                    return value.substring(0, 40) + "..."
                } else {
                    return value;
                }
            },
            showCreditHit(rowData) {
                let credit_per_hit = rowData?.credit_per_hit
                let currentClient = this.$store.getters.getClientId
                if (currentClient) {
                    let customClient = rowData?.clients?.find(clnt => clnt?.client_id == currentClient);
                    if (customClient && customClient?.client_id) {
                        credit_per_hit = customClient.credit_per_hit;
                    }
                }
                console.log('rowdata', rowData?.credit_source_name, credit_per_hit);
                return credit_per_hit;
            },
            showServiceCharge(rowData) {
                let service_charge_per_hit = rowData?.service_charge_per_hit
                let currentClient = this.$store.getters.getClientId
                if (currentClient) {
                    let customClient = rowData?.clients?.find(clnt => clnt?.client_id == currentClient);
                    if (customClient && customClient?.client_id) {
                        service_charge_per_hit = customClient.service_charge_per_hit;
                    }
                }
                return service_charge_per_hit;
            },
            checkCustomData(rowData) {
                let isCustomData = false;
                let currentClient = this.$store.getters.getClientId
                if (currentClient) {
                    if (rowData?.clients?.length > 0 && rowData?.clients?.some(clnt => clnt?.client_id == currentClient)) {
                        isCustomData = true;
                    }
                }
                return isCustomData;
            },
            async setCreditSourceList() {
                try {
                    this.loading = true;
                    // let clinetId = this.$store.getters.getClientId
                    let qParams = new URLSearchParams(this.currentPageData);
                    const { data } = await creditAndSourceList(qParams);
                    this.rowsData = data.data;
                    this.pageData = data?.pagination_info;
                    this.loading = false;
                } catch (error) {
                    this.loading = false;
                    if (Array.isArray(error?.response?.data?.detail)) {
                        this.$toast.error(error?.response?.data?.detail?.[0]?.msg);
                    } else {
                        this.$toast.error(error?.response?.data?.detail);
                    }
                }
            },
            async onPageChange(data, resetPage = false) {
                this.currentPageData = {
                    page: data.currentPage,
                    limit: data.currentPerPage,
                };
                if (resetPage) this.currentPageData.page = 1;
                await this.setCreditSourceList();
            },
        }

    }
</script>
