<template>
    <div class="field-table flex-1 flex flex-col  max-h-full max-w-full  min-h-full text-base-content relative">
        <SubHeader :handleClick="handelUsage" buttonText="Configure Usage" :showBtn="true" class="mx-6" :customCrumbLists="customCrumbLists"/>
        <!-- <div class="flex flex-1 flex-col flex-grow max-h-full max-w-full overflow-auto scroll-bar"> -->
            <vue-good-table :columns="columns" :isLoading="loading" :rows="filteredRowsData" :pagination-options="{ enabled: true, mode: 'records'}">
                <template #column-filter="{ column }">
                    <dropdown
                        ref="setFilterRef"
                        reposition
                        searchable
                        class="filter-dropdown"
                        placeholder="Select"
                        v-if="column?.filterOptions && column?.filterOptions?.enabled"
                        :options="getFilterOptions(column)"
                        @input="(value) => updateColumnFilters(column, value)"
                        :value="filters[column.field]"
                        :limit="1"
                        maxWidth="100%"
                        width="100px"
                        minWidth="100%"
                        :config="{ label: 'name', trackBy: 'id' }"
                        :multiple="false"
                        :taggable="false"
                    >
                    </dropdown>
                
                </template>
                <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field === 'user_name'"
                        :class="[props.row.system_default && 'default hover-text']" class="flex gap-3 pl-3">
                        <router-link :to="{
                                name: 'sourceUsageDetails',
                                params: {id: props.row.source_usage_id}
                            }" class="text-primary whitespace-nowrap cursor-pointer hover:text-dvbrandhoveron">
                                {{ props.row.user_name}}
                        </router-link>
                    </div>
                    <div v-if="props.column.field == 'max_queries_per_month'" class="flex justify-center" >
                        {{props.row.max_queries_per_month}}
                    </div>
                    <div v-if="props.column.field == 'max_queries_per_day'" class="flex justify-center">
                        {{ props.row.max_queries_per_day }}
                    </div>
                    <div v-if="props.column.field == 'credit_source_name'" class="flex justify-center">
                        {{ props.row.credit_source_name }}
                    </div>
                    <div v-if="props.column.field == 'status'" class="flex justify-center capitalize">
                        <span v-if="props.row.active == true" class="capitalize text-green-400">Active</span>
                        <span v-if="props.row.active == false" class="capitalize text-red-500">Inactive</span>

                    </div>
                </template>

                <template #loadingContent>
                    <div class="w-full py-10 justify-center flex">
                        <Loader />
                    </div>
                </template>
            </vue-good-table>
            <!-- </div> -->
            <button 
                v-if="Object.values(filters).flat().length"
                class=" cursor-pointer py-1 px-3 bg-gray-100 hover:bg-gray-200 rounded-md text-xs text-black flex justify-center items-center font-semibold absolute z-50 left-2 top-16"  @click="clearFilters">
                Clear filters
            </button>
    </div>
</template>
<script>
    import SubHeader from "@/components/SubHeader";
    import {getSourceList} from "../services"
    import Loader from "@/components/loader";
    import { uuid } from "vue-uuid";
    import dropdown from "@shared/components/dropdown-base";

    export default {
        name: "credit-packs",
        title: "Credit Admin",
        components: {
            SubHeader,
            Loader,
            dropdown
        },
        data() {
            return {
                columns: [{
                    label: "User",
                    field: "user_name",
                    sortable: true,
                    filterOptions: {
                        enabled: true, 
                    },
                }, {
                    label: "Source",
                    field: "credit_source_name",
                    sortable: true,
                    filterOptions: {
                        enabled: true, 
                    },
                },{
                    label: "Max Quries/Months",
                    field: "max_queries_per_month",
                    sortable: true,
                    filterOptions: {
                        enabled: true, 
                    },
                }, {
                    label: "Max Quries/Days",
                    field: "max_queries_per_day",
                    sortable: true,
                    filterOptions: {
                        enabled: true, 
                    },
                }, {
                    label: "Status",
                    field: "status",
                    sortable: true,
                    filterOptions: {
                        enabled: true, 
                    },
                },  ],
                rowsData: [],
                loading: false,
                userLists: [],
                customCrumbLists: [{name: 'Source Usage'}],
                filters: {},
                filteredRowsData: [],
            }
        },
        async mounted() {
            this.loading = true
            await this.setSourceList()
            this.loading = false
            this.filteredRowsData = this.rowsData;
        },
        methods: {
            async setSourceList()
            {
                let client = this.$store.getters.getClientId
                const { data } = await getSourceList(client)
                this.rowsData = data.source_usages
            },
            
            async handelUsage(){
                this.$router.push({
                    name: "createusage",
                });
            },
            getFilterOptions(column){
                // get unique filter options array
                let filterData = []
                if (column?.field ) {
                    filterData = this.filteredRowsData
                    .filter((row) => row[column.field])
                    .map((row) => (
                        { 
                            id: row.field_type_id ?? uuid.v4(), 
                            label: row[column.field],
                            name:  row[column?.field],
                        }
                    )
                    )
                }
                if (filterData && filterData?.length > 0) {
                    const uniqueMap = new Map();
                    filterData = filterData.forEach(item => {
                      uniqueMap.set(item.name, item);
                    });
                    return Array.from(uniqueMap.values());
                }
                return filterData;
            },
            updateColumnFilters(column, value){
                this.filters[column.field]  =  value?.name ? [value.name] : [] ;
                let result = this.rowsData;
                for (let filter in this.filters) {
                    if (this.filters[filter]?.length) {
                        result = result.filter((el) => this.filters[filter].includes(el[filter]));
                    }
                }
                this.filteredRowsData = result;
            },
            clearFilters() {
                this.filters = {};
                this.filteredRowsData = this.rowsData;
            },            
        }

    }
</script>
<style>
    .filter-dropdown {
        box-shadow: none !important;
        @apply border-transparent border  bg-white;
    }
</style>