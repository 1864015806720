<template>
    <div class="-m-2 wide:-m-4 p-2 wide:p-4 text-base-content" style="height: calc(100% + 32px); width: calc(100% + 32px)">
        <!-- <SubHeader  :backButton="true"  :backRedirectionUrl="`/credit-packs?tab=summary`" class="mb-2"/> -->
        <tabs @select="selectTab" :selected="tabs.selected" :tabs="tabs.list">
            <template #content-creditAllotted> 
                <creditAllotted />
            </template>
            <template #content-creditUsage>
                <creditUsage />
            </template>
        </tabs>
    </div>
</template>
<script>
// import SubHeader from "@/components/SubHeader";
import tabs from "@/components/dashboard-components/tabs";
import creditAllotted from "./credit-allotted-list"
import creditUsage from "./credit-usage-list"

export default {
    title: "Inventory Admin",
    name: "Inventory-Details",
    components: {
        tabs,
        creditAllotted,
        creditUsage,
        // SubHeader
    },
    data() {
        return {
            tabs: {
                selected: "creditAllotted",
                list: [
                    {
                        id: "creditAllotted",
                        label: "Credit Allotted",
                    },
                    {
                        id: "creditUsage",
                        label: "Credit Usage",
                    },
                ],
            },
        
        };
    },
    mounted() {
        this.tabs.selected = this.$route?.query?.tab || "creditAllotted";
    },
    methods: {
        selectTab(id) {
            this.tabs.selected = id;
            this.$router.push({
                path: this.$route.path,
                query: {
                    tab: this.tabs.selected,
                    unique_id: this.$route.query.unique_id
                },
            });
        },
    },
  
};

</script>
<style scoped lang='scss'>
@import "@shared/assets/mixins/mixins.scss";
@include tableComponent;
</style>