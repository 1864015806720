import { render, staticRenderFns } from "./user-table.html?vue&type=template&id=063fa02b&scoped=true&external"
import script from "./user-table.js?vue&type=script&lang=js&external"
export * from "./user-table.js?vue&type=script&lang=js&external"
import style0 from "./user-table.scss?vue&type=style&index=0&id=063fa02b&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "063fa02b",
  null
  
)

export default component.exports