<template>
    <div class="field-table flex-1 flex flex-col  max-h-full max-w-full  min-h-full text-base-content">
        <SubHeader />
        <div class="flex relative flex-1 flex-col flex-grow max-h-full max-w-full">
            <vue-good-table 
                :columns="computedColumns" 
                :isLoading="loading" 
                :rows="rows" 
                :pagination-options="{ enabled: true, mode: 'records'}"
                @on-column-filter="onColumnFilter"
            >
            <template #column-filter="{ column, updateFilters }">
                    <div v-if="column.filterOptions && column.filterOptions.enabled && column.config && column.config.filter_type === 'date'">
                        <DatePicker range v-model="date_range" @input="(value) => updateColumnFilters(column, value, updateFilters)" 
                        :key="date_range_key" class="w-full" format="DD MMM, YYYY" value-type="timestamp"  :placeholder="due_date" />
                    </div>
                    <dropdown
                        :ref="setFilterRef"
                        reposition
                        searchable
                        class="filter-dropdown"
                        placeholder="Select"
                        v-else-if="column.filterOptions && column.filterOptions.enabled && column.config && !['indicators', 'allocation'].includes(column.config.type)"
                        :options="column.filterOptions.filterDropdownItems"
                        @input="(value) => updateColumnFilters(column, value, updateFilters)"
                        :value="getFilterValue(column)"
                        @search="searchFilter(column, $event)"
                        :limit="1"
                        maxWidth="100%"
                        width="100px"
                        minWidth="100%"
                        :config="{ label: 'name', trackBy: 'id' }"
                        :multiple="false"
                        :taggable="false"
                    >
                        <template #caret>
                          <sort-icon class="fill-current" />
                        </template>
                    </dropdown>
                    <div class="" v-else>
                        <!-- // adding a blank div to remove the default template   -->
                    </div>
                </template>
                <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field === 'name'"
                        :class="[props.row.system_default && 'default hover-text']" class="flex gap-3 pl-3">
                        <router-link :to="{
                                name: 'creditPackDetails',
                                params: {id: props.row.base_credit_pack_id}
                            }" class="text-primary whitespace-nowrap cursor-pointer hover:text-dvbrandhoveron">
                                {{ props.row.name}}
                        </router-link>
                    </div>
                    <div v-if="props.column.field == 'pack_credits'" class="flex justify-center">
                        {{props.row.pack_credits}}
                    </div>
                    <div v-if="props.column.field == 'validity'" class="flex justify-center">
                        {{props.row.validity}} Days
                    </div>
                    <div v-if="props.column.field == 'discount'" class="flex justify-center">
                        {{props.row.discount}} %
                    </div>
                    <div v-if="props.column.field == 'discounted_price'" class="flex justify-center">
                        {{ props.row.currency_symbol }} {{props.row.discounted_price}} 
                    </div>
                    <div v-if="props.column.field == 'available_packs'" class="flex justify-center">
                        {{props.row.available_packs}} 
                    </div>
                    <div v-if="props.column.field == 'list_price'" class="flex justify-center">
                        {{ props.row.currency_symbol }} {{props.row.list_price}}
                    </div>
                </template>

                <template #loadingContent>
                    <div class="w-full py-10 justify-center flex">
                        <Loader />
                    </div>
                </template>
            </vue-good-table>
            <button 
                v-if="isFilters"
                class=" cursor-pointer py-1 px-3 bg-gray-100 hover:bg-gray-200 rounded-md text-xs text-black flex justify-center items-center font-semibold absolute  left-2 z-50" :class="isSelectionChanged ? 'top-14' : 'top-2' " @click="clearFilters">
                Clear filters
            </button>
        </div>
    </div>
</template>
<script>
    import SubHeader from "@/components/SubHeader";
    import {getCreditPack} from "../services"
    import Loader from "@/components/loader";
    import {FormatDateNew} from "@/plugins/functions";
    import DatePicker from "vue2-datepicker";
    import dropdown from "@shared/components/dropdown-base";
    import { onlyUnique } from "@shared/plugins/utils.js";
    import { uuid } from "vue-uuid";
    import { DateTime } from "luxon";
    import { format , parse } from "date-fns";

    export default {
        name: "credit-packs",
        title: "Credit Admin",
        components: {
            SubHeader,
            Loader,
            DatePicker,
            dropdown,
        },
        data() {
            return {
                columns: [{
                    label: "Pack Name",
                    field: "name",
                    config: {
                      filter: true,
                      alignCenter: true,
                    },
                }, {
                    label: "Credits",
                    field: "pack_credits",
                }, {
                    label: "Pack Validity",
                    field: "validity",
                    config: {
                      filter: true,
                      alignCenter: true,
                    },
                }, {
                    label: "List Price",
                    field: "list_price",
                    config: {
                      filter: true,
                      alignCenter: true,
                    },
                }, {
                    label: "Discount",
                    field: "discount",
                    config: {
                      filter: true,
                      alignCenter: true,
                    },
                },  {
                    label: "Discounted Price",
                    field: "discounted_price",
                    config: {
                      filter: true,
                      alignCenter: true,
                    },
                },{
                    label: "Available Packs",
                    field: "available_packs",
                }, ],
                rows: [],
                serverParams: {},
                filterRefs: [],
                filterOptions: {},
                searchLoading:{},
                isSelectionChanged: false,
                loading: false,
            }
        },
        async mounted() {
            this.loading = true
            await this.setCreditPacks()
            this.loading = false

        },
        computed: { 
          isFilters() {
            return this.serverParams && Object.entries(this.serverParams).length;
          },
          columnFilterOptions() {
            return this.getFilterOptions(this.columnOption, this.rows);
          },
          columnOption() {
            return this.columns.map((el) => ({
              ...el,
              config: 
              {
                ...el.config,
                isLoading: !!this.searchLoading[el.field] || false,
              },
            }));
          },
          computedColumns() {
            // use option list from server if it is:
            const options = this.columnFilterOptions;
            // had to check for empty object
            if (options && Object.entries(options).length) {
              return this.columns.map((col) => {
                const filtered = col.config?.filter ?? true;
                if (col.prefetchedOptions && col.prefetchedOptions.length) {
                  return {
                    ...col,
                    filterOptions: {
                      enabled: filtered,
                      filterDropdownItems: col.prefetchedOptions,
                    },
                  };
                } else {
                  return {
                    ...col,
                    filterOptions: {
                      enabled: filtered,
                      filterDropdownItems: options[col.field],
                    },
                  };
                }
              });
            } else {
              // TODO remove/rework default filters
              return this.columns.map((col) => {
                const filtered = col.config?.filter ?? true;
                return {
                  ...col,
                  filterOptions: filtered
                    ? {
                      enabled: true,
                      placeholder: "All",
                      filterDropdownItems: this.rows
                        .filter((row) => row[col.field])
                        .map((row) => ({ id: row.field_type_id ?? uuid.v4(), name: row[col.field] }))
                        .filter(onlyUnique),
                    }
                    : undefined,
                };
              });
            }
          }
        },
        methods: {
            FormatDateNew,
            async setCreditPacks(){
                this.loading = true;
                const filterParams = this.makeFilterParams();
                let qParams
                // qParams = new URLSearchParams(this.currentPageData);
                if (filterParams) {
                    qParams = new URLSearchParams({...filterParams});
                }
                const {data} = await getCreditPack(qParams)
                this.rows = data.credit_packs;
                this.loading = false;
            },
            async clearFilters() {
                this.serverParams = null;
                await this.setCreditPacks();
            },
            async onColumnFilter({ columnFilters }) {
            //   this.currentPageData.page = 1;
              this.serverParams = columnFilters ? { ...columnFilters } : null;
              await this.setCreditPacks();
            },
            selectionChanged(e) {
              if (e) {
                this.isSelectionChanged = e.selectedRows && e.selectedRows.length !== 0;
                this.$emit('on-selected-rows-change', e.selectedRows)
              }
            },
            getFilterOptions(columns, row) {
                return columns.reduce((result, curr) => {
                  result[curr.field] = row
                    .filter((row) => row[curr.field])
                    .map((row) => row[curr.field])
                    .filter(onlyUnique)
                    .map((el) => (
                      { 
                        id: uuid.v4(), name: el 
                      }));
                      if (curr?.field == 'order_date' || curr?.field == 'sale_date') {
                        result[curr.field] = result[curr.field]?.map(el => {
                          return {
                            ...el,
                            name: FormatDateNew(new Date(el.name)),
                            label: el.name
                          }
                        })
                      }
                      if (curr?.field == 'clients') {
                        let filterArray = [];
                        result[curr.field]?.map(el => {
                          el?.name?.map(innerEl => {
                            if (!filterArray?.some(fltrEl => fltrEl?.name == innerEl?.client_name)){
                              filterArray?.push({
                                ...el,
                              name: innerEl?.client_name,
                            })
                          }
                          })
                        })
                        result[curr.field] = filterArray;
                      }
                      return result;
                    }, 
                {});
            },
            makeFilterParams() {
                let filterParams = {};
                if (this.serverParams) {
                  Object.keys(this.serverParams).map((key) => {
                    if (key == 'order_date' || key == 'sale_date') {
                      filterParams[key] = this.serverParams[key] ? this.serverParams[key]["label"] : delete filterParams[key];
                    } else {
                      filterParams[key] = this.serverParams[key] ? this.serverParams[key]["name"] : delete filterParams[key];
                    }
                    if (this.serverParams[key]["name"] === undefined) {
                      delete filterParams[key];
                    }
                  });
                }
                return filterParams;
            },
            updateColumnFilters(column, value /* , callback */) {
                let filterValObj = {
                  ...this.serverParams,
                  [column.query_key || column.field]: value ? {
                    ...value,
                    field_type: column.field_type,
                  } : null,
                };
                if (!filterValObj[column.query_key || column.field]) {
                  delete filterValObj[column.query_key || column.field];
                }
                if (column.query_key && ["from_due_date", "from_created_at"].includes(column.query_key)) {
                  let date = DateTime.fromISO(value.name).toISODate()
                  if (date == null) {
                    const parsedDate = parse(value.name, 'd MMM yyyy', new Date());
                    date = format(parsedDate, 'yyyy-MM-dd');
                    if (filterValObj.from_created_at) {
                      filterValObj.from_created_at.name = date.toString().substring(10, 0)
                    } else {
                      filterValObj.from_due_date.name = date.toString().substring(10, 0)
                    }
                  }
                  Object.assign(filterValObj, {
                    [`to_${column.field}`]: {
                      id: date.ts,
                      name: date.toString().substring(10, 0),
                      field_type: column.field_type
                    },
                  });
                }
                if (column.config && /* column.config.query_keys && */ column.config.filter_type === 'date') { // uncommment when you want to add customkeys for backend filters
                  let [start, end] = value.map(el => DateTime.fromJSDate(new Date(el)))
                  Object.assign(filterValObj, {
                    [column.config.query_keys['start']]: {
                      id: start.ts,
                      name: value[0] ? start.toString() : null,
                      field_type: column.field_type
                    },
                  })
                  Object.assign(filterValObj, {
                    [column.config.query_keys['end']]: {
                      id: end.ts,
                      name: value[1] ? end.toString() : null,
                      field_type: column.field_type
                    },
                  })
                }
                this.onColumnFilter( {
                  columnFilters: filterValObj,
                });
            },
            searchFilter(column, value) {
              this.$emit("search-filter", { column, value });
            },
            setFilterRef(el) {
              if (el) {
                this.filterRefs.push(el);
              }
            },
            getFilterValue(col) {
                if (this.isFilters) {
                  return this.serverParams[col.field] ?? this.serverParams[col.query_key];
                } else return null;
            },
        }

    }
</script>
