<template>
    <div class="field-table flex-1 flex flex-col  max-h-full max-w-full  min-h-full text-base-content">
        <SubHeader  class="mx-6" :customCrumbLists="customCrumbLists"/>
        <div class="flex flex-1 flex-col flex-grow max-h-full max-w-full overflow-auto scroll-bar">
            <common-table 
                :columns="columns"
                :isLoading="loading" 
                sortingEnabled
                :rows="rowsData" 
                :filters="serverParams"
                :sort-options="{
                    enabled: true,
                }"
                @sort-change="onSortChanged"
                :filterOptions="columnFilterOptions"
                :filterOptionsAsync="columnFilterOptionsAsync"
                @activatePack="activatePack"
                :pagination-options="{ enabled: true, mode: 'records', dropdownAllowAll: false,}" 
                :totalRows="pageData.total_count"
                @page-change="onPageChange($event)"
                @column-filter="onColumnFilter"
                @filters-clear="clearFilters"
                @per-page-change="onPageChange($event, true)"
                @search-filter="getSearchOptionsTick($event)"
                :config="{ sortingOption: { enabled: true } }"
            >
                <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field === 'unique_id'"
                        :class="[props.row.system_default && 'default hover-text']" class="flex gap-3 pl-3">
                        <router-link :to="{
                                name: 'inventoryDetails',
                                params: {id: props.row.credit_pack_id}
                            }" class="text-primary whitespace-nowrap cursor-pointer hover:text-dvbrandhoveron">
                                {{ props.row.unique_id}}
                        </router-link>
                    </div>
                     <div v-if="props.column.field == 'credit_pack_name'" class="flex justify-center">
                        {{props.row.credit_pack_name}}
                    </div>
                    <div v-if="props.column.field == 'total_credits'" class="flex justify-center">
                        {{props.row.total_credits}}
                    </div>
                    <div v-if="props.column.field == 'remaining_credits'" class="flex justify-center">
                        {{ props.row.status == 'Available' ? '-' : props.row.remaining_credits }}
                    </div>
                    <div v-if="props.column.field == 'validity'" class="flex justify-center">
                        {{props.row.validity}} Days
                    </div>
                    <div v-if="props.column.field == 'alloted_date'" class="flex justify-center">
                        {{ props?.row?.alloted_date ? FormatDate(new Date(props?.row?.alloted_date)) : '-' }}
                    </div>
                    <div v-if="props.column.field == 'purchase_date'" class="flex justify-center">
                        {{ props?.row?.purchase_date ? FormatDate(new Date(props?.row?.purchase_date)) : '-' }}
                    </div>
                    <div v-if="props.column.field == 'activated_at'" class="flex justify-center">
                        {{ props?.row?.activated_at ? FormatDate(new Date(props?.row?.activated_at)) : '-' }}
                    </div>
                    <div v-if="props.column.field == 'valid_till'" class="flex justify-center">
                        {{ props?.row?.valid_till ? FormatDate(new Date(props?.row?.valid_till)) : '-' }}
                    </div>
                    <div v-if="props.column.field == 'client_order_id'" class="flex justify-center">
                        {{ props.row.client_order_id }}
                    </div>
                    <div v-if="props.column.field == 'status'" class="flex justify-center capitalize">
                        <span :class="getStatusColor(props.row.status)">{{ props.row.status }}</span>
                    </div>
                    <div v-if="props.column.field == 'action'" class="flex justify-center cursor-pointer" @click="activatePack(props.row.credit_pack_id)">
                        <span class="text-red-400">{{ props.row.action }}</span>
                    </div>
                </template>

                <template #loadingContent>
                    <div class="w-full py-10 justify-center flex">
                        <Loader />
                    </div>
                </template>
            </common-table>
        </div>
    </div>
</template>
<script>
    import SubHeader from "@/components/SubHeader";
    import { getCreditsInventory, activateCreditPack } from "../services"
    import Loader from "@/components/loader";
    import {FormatDate} from "@/plugins/functions"
    import commonTable from "@/components/common-table"
    import { onlyUnique } from "@/plugins/utils.js";
    import { uuid } from "vue-uuid";
    import axios from "@/axios"

    export default {
        name: "credit-packs",
        title: "Credit Admin",
        components: {
            SubHeader,
            Loader,
            commonTable,
        },
        data() {
            return {
                columns: [  
                    { 
                        label: "Unique Id", 
                        field: "unique_id",
                        config: {
                            filter: true,
                            type: "link",
                            alignCenter: true,
                        },
                        tdClass: "truncate max-w-xs",
                    },
                    { 
                        label: "Pack Name", 
                        field: "credit_pack_name",
                        config: {
                            filter: true,
                            alignCenter: true,
                        },
                    },
                    { 
                        label: "Credits", 
                        field: "total_credits",
                        config: {
                            filter: true,
                            alignCenter: true,
                        },
                    },
                    { 
                        label: "Balance", 
                        field: "remaining_credits",
                        // config: {
                        //     filter: true,
                        //     alignCenter: true,
                        // },
                    },
                    { 
                        label: "Validity", 
                        field: "validity",
                        config: {
                            filter: true,
                            alignCenter: true,
                        }, 
                    },
                    { 
                        label: "Client Order Id", 
                        field: "client_order_id",
                        config: {
                            filter: true,
                            alignCenter: true,
                        },
                    },
                    { 
                        label: "Purchase Date", 
                        field: "purchase_date",
                        config: {
                            filter: true,
                            // filter_type: 'date',
                            type: 'showDate',
                            alignCenter: true,
                        }, 
                    },
                    { 
                        label: "Alloted Date", 
                        field: "alloted_date",
                        config: {
                            filter: true,
                            // filter_type: 'date',
                            type: 'showDate',
                            alignCenter: true,
                        }, 
                    },
                    { 
                        label: "Start Date", 
                        field: "activated_at",
                        config: {
                            filter: true,
                            // filter_type: 'date',
                            type: 'showDate',
                            alignCenter: true,
                        },  
                    },
                    { 
                        label: "Expiry Date", 
                        field: "valid_till",
                        config: {
                            filter: true,
                            // filter_type: 'date',
                            type: 'showDate',
                            alignCenter: true,
                        }, 
                    },
                    { 
                        label: "Status", 
                        field: "status",
                        config: {
                            filter: true,
                            type: 'status',
                            alignCenter: true,
                        }, 
                    }, 
                    { 
                        label: "Action", 
                        field: "action",
                        config: {
                            filter: true,
                            type: 'action_button',
                            alignCenter: true,
                        },
                    },
                ],
                serverParams: null,
                searchKey: null,
                columnFilterOptionsAsync: {},
                searchLoading: {},
                rowsData: [],
                loading: false,
                userLists: [],
                pageData: {
                    total_pages: 0,
                },
                currentPageData: {
                    page: 1,
                    limit: 10,
                },
                customCrumbLists: [{name: 'Inventory'}]
            }
        },
        computed: {
            columnFilterOptions() {
                return this.getFilterOptions(this.columnOption, this.rowsData);
            },
            columnOption() {
                return this.columns.map((el) => ({
                    ...el,
                    config: {
                        ...el.config,
                        isLoading: !!this.searchLoading[el.field] || false,
                    },
                }));
            },
        },

        async mounted() {
            this.loading = true
            await this.setCreditPacks()
            this.loading = false

        },
        methods: {
            FormatDate,
            async setCreditPacks()
            {
                const filterParams = this.makeFilterParams();
                const {data} = await getCreditsInventory(`page=${this.currentPageData.page}&limit=${this.currentPageData.limit}`, filterParams)
                this.rowsData = data.credit_packs_inventory;
                this.pageData = data.pagination_info;
            },
            async clearFilters() {
                this.serverParams = null;
                await this.setCreditPacks();
            },
            async onSortChanged(data) {
                if (data && data.length && data[0].type !== "none") {
                    data = data[0];
                    this.sortParams = {
                        sort_key: data.field,
                        sort_value: data.type,
                    };
                    await this.setCreditPacks();
                } else {
                    this.sortParams = null;
                    await this.setCreditPacks();
                }
            },
            async onColumnFilter({ columnFilters }) {
                this.currentPageData.page = 1;
                this.serverParams = columnFilters ? { ...columnFilters } : null;
                await this.setCreditPacks();
            },
            getFilterOptions(columns, row) {
                return columns.reduce((result, curr) => {
                    result[curr.field] = row
                        .filter((row) => row[curr.field])
                        .map((row) => row[curr.field])
                        .filter(onlyUnique)
                        .map((el) => ({ id: uuid.v4(), name: el }));
                    return result;
                }, {});
            },
            async activatePack(creditpack){
                try{
                    this.loading = true
                    await activateCreditPack(creditpack)
                    this.$toast.success("Pack Activated")
                    await this.setCreditPacks()
                    this.loading = false
                }
                catch(error){
                    this.loading = false
                    this.$toast.error("Something Went Wrog")
                }
               
            },
            getStatusColor(status) {
                if (status.toLowerCase() == "in use") {
                    return "text-yellow-500"
                }
                if (status.toLowerCase() == 'active') {
                    return 'text-green-400'
                }
            },
            async getSearchOptions(payload) {
                if (!payload.value) this.columnFilterOptionsAsync = null;
                else {
                    this.$set(this.searchLoading, payload.column.field, true);
                    try {
                        let qParams = new URLSearchParams(this.currentPageData);
                        const sortParam = {
                            sort_value: "asc",
                        };
                        const filterParams = this.makeFilterParams();
                        const requestData = {
                            ...filterParams,
                            [payload.column.query_key || payload.column.field]: payload.value,
                            ...sortParam,
                        };
                        let url = `credit-pack-inventory/client/credit_pack_inventory/?${qParams}`;
                        const { data } = await axios.get(url, {
                            params: requestData,
                        });
                        this.columnFilterOptionsAsync = this.getFilterOptions(
                            this.columnsProps,
                            data?.data
                        );
                    } catch (error) {
                        console.error(error);
                    }
                    this.$set(this.searchLoading, payload.column.field, false);
                }
            },
            makeFilterParams() {
                let filterParams = {};
                if (this.serverParams) {
                    Object.keys(this.serverParams).map((key) => {
                        filterParams[key] = this.serverParams[key]
                        ? this.serverParams[key]["name"]
                        : delete filterParams[key];
                        if (this.serverParams[key]["name"] === undefined) {
                            delete filterParams[key];
                        }
                        if (key.includes("date_from") || key.includes("date_to")) {
                            filterParams[key] = this.formatDate(filterParams[key]);
                        }
                    });
                }
                return filterParams;
            },
            async onPageChange(data, resetPage = false) {
                this.currentPageData = {
                    page: data.currentPage,
                    limit: data.currentPerPage,
                };
                if (resetPage) this.currentPageData.page = 1;
                await this.setCreditPacks();
            },
            dateFilterFn(data, filterString) {
                let givenDate = FormatDate(new Date(data)).toLowerCase();
                return givenDate.includes(filterString.toLowerCase());
            }
        }

    }
</script>


