<template>
    <div class="table-large relative w-full" :style="cssVars">
       
        <!-- // ! pls dont use height with calc,  -->
        <vue-good-table 
            ref="table" 
            mode="remote" 
            @on-page-change="$emit('page-change', $event)" 
            @on-sort-change="$emit('sort-change', $event)" 
            @on-per-page-change="$emit('per-page-change', $event)" 
            @on-column-filter="onColumnFilter" 
            :sort-options="{ enabled: sortingEnabled }" 
            v-bind="config" 
            :totalRows="totalRows" 
            :isLoading="isLoading"
            :pagination-options="getPaginationOptions" 
            :rows="rows" 
            :columns="computedColumns" 
            styleClass="vgt-table bordered vgt-table-large"   
            @on-selected-rows-change="selectionChanged">

            <template #column-filter="{ column, updateFilters }">
                <div v-if="column.filterOptions && column.filterOptions.enabled && column.config && column.config.filter_type === 'date'">
                    <DatePicker range v-model="date_range" @input="(value) => updateColumnFilters(column, value, updateFilters)" 
                        :key="date_range_key" class="w-full" format="DD MMM, YYYY" value-type="timestamp"  :placeholder="due_date" />
                    </div>
                <dropdown
                    :ref="setFilterRef"
                    reposition
                    searchable
                    class="filter-dropdown"
                    placeholder="Select"
                    v-else-if="column.filterOptions && column.filterOptions.enabled && column.config && !['indicators', 'allocation'].includes(column.config.type)"
                    :options="column.filterOptions.filterDropdownItems"
                    @input="(value) => updateColumnFilters(column, value, updateFilters)"
                    :value="getFilterValue(column)"
                    @search="searchFilter(column, $event)"
                    :limit="1"
                    maxWidth="100%"
                    width="100px"
                    minWidth="100%"
                    :config="{ label: 'name', trackBy: 'id' }"
                    :loading="column.config.isLoading"
                    :multiple="false"
                    :taggable="false"
                    :units="column.config.units"
                >
                    <template #caret>
                        <sort-icon class="fill-current" />
                    </template>
                </dropdown>
               
                <div class="" v-else>
                    <!-- // adding a blank div to remove the default template   -->
                </div>
            </template>
            <template #table-column="props">
                <span v-if="props.column.config && props.column.config.icon" class="th-col th-col--icon">
                    <img class="icon" :src="props.column.config && props.column.config.icon" />
                    {{ props.column.label }}
                </span>
                <span class="th-col" v-else>
                    {{ props.column.label }}
                </span>
            </template>
            <template #table-row="props">
                <slot name="link" :customData="props.row" v-if="props.column.config && props.column.config.type === 'link'">
                    <router-link :to="{
                                name: 'inventoryDetails',
                                params: {id: props.row.credit_pack_id},
                                query: {unique_id: props?.row?.unique_id}
                            }" class="text-primary whitespace-nowrap cursor-pointer hover:text-dvbrandhoveron">
                                {{ props.row.unique_id}}
                        </router-link>
                </slot>
                <!-- <div class="cursor-pointer" v-else-if="props.column.config && props.column.config.title && props.column.config.type !== 'link'">
                    <rowTitle :value="props.formattedRow[props.column.field]" :isValue=true />
                </div> -->
                <!-- svg icon -->
                <slot name="svg_icon" v-else-if="props.column.config && props.column.config.type === 'svg_icon'" :customData="props.row" class="flex justify-center" >
                </slot>

                <slot name="status_text" v-else-if="props.column.config && props.column.config.type === 'status_text'" :customData="props.row" class="flex justify-center">
                </slot>

                <!-- Payment status check icon -->
                <div v-else-if="props.column.config && props.column.config.type === 'payment_status'" :class="props.column.config.customClass">
                    <slot name="payment_status" :customData="props.row" ></slot>
                </div>

                <slot name="rich_text" :customData="props.row" v-else-if="props.column.config && props.column.config.type === 'rich_text'">
                    {{ props.formattedRow[props.column.field] }}
                </slot>
                <slot name="source" :customData="props.row" v-else-if="props.column.config && props.column.config.type === 'source'">
                    {{ props.formattedRow[props.column.field] }}
                </slot>
                <slot name="actions" class="cursor-pointer" :customData="props.row" v-else-if="props.column.config && props.column.config.type === 'actions'" >
                    <span  class="">{{ props.formattedRow[props.column.field] }}</span>
                </slot>
                <div v-else-if="props.column.config && props.column.config.type == 'lock'" class="flex justify-center" :class="props.column.config.customClass" :customData="props.row">
                    <button class="h-8 w-8 disabled:opacity-50 ml-3" v-if="props.row.lock == true" v-tippy :content="props.column.config.tab">
                        <!-- <font-awesome-icon icon="lock" class="text-blue-600 text-lg fa-3x" /> -->
                        <svg width="15.75" height="18.375" viewBox="0 0 15.75 18.375" fill="none" version="1.1" id="svg4" sodipodi:docname="0edb01c6933bcd8f0ece7fd255220d19.svg" xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape" xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg">
                        <defs id="defs8"/>
                        <sodipodi:namedview id="namedview6" pagecolor="#ffffff" bordercolor="#666666" borderopacity="1.0" inkscape:pageshadow="2" inkscape:pageopacity="0.0" inkscape:pagecheckerboard="0"/>
                        <path d="m 2.625,7.875 v 8.75 H 14 v -8.75 z m 10.5,-1.75 h 1.75 c 0.4833,0 0.875,0.39175 0.875,0.875 v 10.5 c 0,0.4833 -0.3917,0.875 -0.875,0.875 h -14 C 0.39175,18.375 0,17.9833 0,17.5 V 7 C 0,6.51675 0.39175,6.125 0.875,6.125 h 1.75 V 5.25 C 2.625,2.3505 4.9755,0 7.875,0 c 2.8995,0 5.25,2.3505 5.25,5.25 z m -1.75,0 V 5.25 c 0,-1.933 -1.567,-3.5 -3.5,-3.5 -1.933,0 -3.5,1.567 -3.5,3.5 V 6.125 Z M 3.5,8.75 H 5.25 V 10.5 H 3.5 Z m 0,2.625 h 1.75 v 1.75 H 3.5 Z M 3.5,14 h 1.75 v 1.75 H 3.5 Z" fill="#0d69d5" id="path2"/>
                        </svg>           
                    </button>
                </div>

                <slot name="tooltip_text" :customData="props.row" v-else-if="props.column.config && props.column.config.type === 'tooltip_text'">
                    <span v-tippy :content="props.formattedRow[props.column.field]">{{ props.formattedRow[props.column.field] }}</span>
                </slot>

                <slot name="show_info" class="cursor-pointer" :customData="props.row" v-else-if="props.column.config && props.column.config.type === 'show_info'">
                    <span>{{ props.formattedRow[props.column.field] }}</span>
                </slot>

                <slot name="show_blocks" class="cursor-pointer" :customData="props.row" v-else-if="props.column.config && props.column.config.type === 'show_blocks'">
                    <span>{{ props.formattedRow[props.column.field] }}</span>
                </slot>
                <!-- <button v-else-if="props.column.config && props.column.config.type === 'action_button' && props.row.action != ''" class="text-sm font-semibold normal-case px-6 leading-3 h-auto min-h-0 py-2.5 gap-2 flex items-center no-animation focus:bg-blue-700 rounded-xl btn-primary m-auto" @click="$emit('activatePack', props.row)">{{ props.row.action  }}</button> -->
                <div v-else-if="props.column.config && props.column.config.type === 'status'" class="flex justify-center capitalize">
                    <span :class="getStatusColor(props.row.status)">{{ props.row.status }}</span>
                </div>
                <div v-else-if="props.column.config && props.column.config.type === 'action_button' && props.row.action != ''" class="flex justify-center cursor-pointer" @click="$emit('activatePack', props.row.credit_pack_id)">
                    <span class="text-red-400">{{ props.row.action }}</span>
                </div>
                <div v-else-if="props.column.field == 'remaining_credits'" class="flex justify-center">
                    {{ props.row.status == 'Available' ? '-' : props.row.remaining_credits }}
                </div>
                <div v-else-if="props.column.config && props.column.config.type === 'user_allocation'" class="whitespace-nowrap px-2 py-1 text-sm text-primary-300 flex justify-center">
                    <button :disabled="!props.column.config.isAllowed" :class="[!props.column.config.isAllowed &&'cursor-not-allowed']" class="flex disabled:opacity-50 flex-col gap-1 items-center relative cursor-pointer" @click="onAllocation(props, 'users')" :title="checkAllocation(props, 'users') ? `Users: ${props.row.work_allocation.users}` : ''">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 fill-current" :class="checkAllocation(props, 'users') ? 'text-primary' : 'text-primary-200'" viewBox="0 0 448 512">
                            <path d="M224 256A128 128 0 1 1 224 0a128 128 0 1 1 0 256zM209.1 359.2l-18.6-31c-6.4-10.7 1.3-24.2 13.7-24.2H224h19.7c12.4 0 20.1 13.6 13.7 24.2l-18.6 31 33.4 123.9 36-146.9c2-8.1 9.8-13.4 17.9-11.3c70.1 17.6 121.9 81 121.9 156.4c0 17-13.8 30.7-30.7 30.7H285.5c-2.1 0-4-.4-5.8-1.1l.3 1.1H168l.3-1.1c-1.8 .7-3.8 1.1-5.8 1.1H30.7C13.8 512 0 498.2 0 481.3c0-75.5 51.9-138.9 121.9-156.4c8.1-2 15.9 3.3 17.9 11.3l36 146.9 33.4-123.9z" />
                        </svg>
                        <!-- <span class="text-2xs font-semibold">Users</span> -->
                        <span v-if="checkAllocation(props, 'users')" class="absolute -top-3 -right-2 w-5 h-5 text-2xs flex justify-center items-center rounded-full bg-primary-300 p-2 text-white-text">
                            {{ checkIfAllocationValueIsLarge(props.row.work_allocation.users) }}
                        </span>
                    </button>
                </div>
                <div v-else-if="props.column.config && props.column.config.type === 'action_edit'" class="whitespace-nowrap py-2 text-sm text-primary-300 flex justify-center">
                    <svg @click="$emit('on-edit', props)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-primary cursor-pointer opacity-80">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                    </svg>
                </div>
                <!-- Show date in format like 13 aug 2023 -->
                <div v-else-if="props.column.config && props.column.config.type === 'showDate'" class="flex justify-center">
                    <span>{{ props.formattedRow[props.column.field] ? format(new Date(props.formattedRow[props.column.field]), 'dd LLL yyyy') : '-' }}</span>
                </div>          
                <span v-else :class="props?.column?.config?.alignCenter ? 'flex justify-center' : ''">
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </template>
            <template #emptystate>
                <div class="alert alert-info py-5 my-5" v-if="!isLoading && !rows.length">No Data Found</div>
                <div v-else class="py-16"></div>
            </template>
            <template #selected-row-actions>
                <div class="flex items-center mb-2">
                    <button class="inline-flex items-center px-4 py-2 bg-primary cursor-pointer hover:bg-blue-600 text-white-text text-sm font-medium rounded-md" @click="onWorkAllocation()">
                        <slot name="workAllocation"> Case Allocation </slot>
                    </button>
                </div>
            </template>
            <template #loadingContent>
                <div class="w-full py-10 justify-center flex">
                    <Loader />
                </div>
            </template>
        </vue-good-table>
        <button v-if="isFilters" 
            class=" cursor-pointer py-1 px-3 bg-gray-100 hover:bg-gray-200 rounded-md text-xs text-black flex justify-center items-center font-semibold absolute z-50 left-2" :class="isSelectionChanged ? 'top-14' : 'top-2' " @click="clearFilters">
            Clear filters
        </button>
    </div>
</template>

<script>
// import status from "./status";
// import Clone from "./clone";
// import indicators from "./indicators";
// import rowTitle from "./rowTitle"
import dropdown from "@shared/components/dropdown-base";
import sortIcon from "@shared/assets/svg/dropdown-double.svg";
import { onlyUnique } from "@shared/plugins/utils.js";
import { uuid } from "vue-uuid";
import { DateTime } from "luxon";
import Loader from "@shared/loader";
import DatePicker from "vue2-datepicker";
import { format , parse } from "date-fns";



// import { isEqual } from 'lodash';

export default {
    name: "table-large",
    components: {
        // status,
        // indicators,
        dropdown,
        sortIcon,
        Loader,
        // Clone,
        DatePicker,
        // rowTitle
    },
    props: {
        due_date:{
            type: String,
            default:()=>"select dates"
        },
        config: {
            type: Object,
            default: () => {},
        },
        /* Set config: {type, icon} to use particular component in td or add icon to th */
        columns: {
            type: Array,
            required: true,
        },
        rows: {
            type: Array,
            default: () => [],
        },
        filters: {
            type: Object,
            default: null,
        },
        filterOptions: {
            type: Object,
            default: null,
        },
        filterOptionsAsync: {
            type: Object,
            default: null,
        },
        totalRows: {
            type: Number,
            default: 0,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        colorHeader: {
            type: String,
            default: "#029ADB",
        },
        colorRow: {
            type: String,
            default: "#F2F2F2",
        },
        colorBorder: {
            type: String,
            default: "#D9D9D9",
        },
        colorTextHeader: {
            type: String,
            default: "#FFF",
        },
        // handleCaseClick:{ // TODO check if is still actual
        //     type: Function,
        //     default: () => {}
        // }
        sortingEnabled: {
            type: Boolean,
            default: false,
        },
        paginationOptions: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            // paginationOptions: {
            //     enabled: true,
            //     dropdownAllowAll: false,
            // },
            filterRefs: [],
            date_range: [],
            date_range_key: 1,
            isSelectionChanged: false
        };
    },
    // watch: {
    //     filters(val) {
    //         console.log('watch filters', val);
    //     },
    // },
    computed: {
        isSelected() {
            return  (this.$refs.table?.selectedRows && this.$refs.table?.selectedRows.length && this.$refs.table?.selectedRows.length) 
        },
        isFilters() {
            return this.filters && Object.entries(this.filters).length;
        },
        computedColumns() {
            // use option list from server if it is:
            const options = this.filterOptionsAsync || this.filterOptions;
            // had to check for empty object
            if (options && Object.entries(options).length) {
                return this.columns.map((col) => {
                    const filtered = col.config?.filter ?? true;
                    if (col.prefetchedOptions && col.prefetchedOptions.length) {
                        return {
                            ...col,
                            filterOptions: {
                                enabled: filtered,
                                filterDropdownItems: col.prefetchedOptions,
                            },
                        };
                    } else {
                        return {
                            ...col,
                            filterOptions: {
                                enabled: filtered,
                                filterDropdownItems: options[col.field],
                            },
                        };
                    }
                });
            } else {
                // TODO remove/rework default filters
                return this.columns.map((col) => {
                    const filtered = col.config?.filter ?? true;
                    return {
                        ...col,
                        filterOptions: filtered
                            ? {
                                  enabled: true,
                                  placeholder: "All",
                                  filterDropdownItems: this.rows
                                      .filter((row) => row[col.field])
                                      .map((row) => ({ id: row.field_type_id ?? uuid.v4(), name: row[col.field] }))
                                      .filter(onlyUnique),
                              }
                            : undefined,
                    };
                });
            }
        },
        cssVars() {
            return {
                // TODO use theme colors
                "--color-header": this.colorHeader,
                "--color-row": this.colorRow,
                "--color-border": this.colorBorder,
                "--color-text-header": this.colorTextHeader,
            };
        },
        getPaginationOptions() {
            return { ...this.paginationOptions, dropdownAllowAll: !!this.paginationOptions?.dropdownAllowAll };
        },
    },
    methods: {
        format,
        getColor(row) {
            if (row && row.row.case_status_color) return row.row.case_status_color
            else return '#929192'
        },
        selectionChanged(e) {
            // console.log('e :>> ', e);
            if (e) {
                this.isSelectionChanged = e.selectedRows && e.selectedRows.length !== 0;
                this.$emit('on-selected-rows-change', e.selectedRows)
            }
        },
        onColumnFilter(e) {
            console.log("onColumnFilter", e);
        },
        clearFilters() {
            this.$emit("filters-clear");
            this.date_range = []
        },
        getStatusColor(status) {
            if (status.toLowerCase() == "in use") {
                return "text-yellow-500"
            }
            if (status.toLowerCase() == 'available') {
                return 'text-green-400'
            }
        },
        // handleViewCase(caseData){ // TODO check if is still actual
        //     this.handleCaseClick(caseData)
        // },
        searchFilter(column, value) {
            this.$emit("search-filter", { column, value });
        },
        setFilterRef(el) {
            if (el) {
                this.filterRefs.push(el);
            }
        },
        handleDatePicker(eve) {
            console.log('eve :>> ', eve);
        },
        updateColumnFilters(column, value /* , callback */) {
            let filterValObj = {
                ...this.filters,
                [column.query_key || column.field]: value ? { 
                    ...value, 
                    field_type: column.field_type,
                } : null,
            };
            if (!filterValObj[column.query_key || column.field]) {
                delete filterValObj[column.query_key || column.field];
            }
            if (column.query_key && ["from_due_date", "from_created_at"].includes(column.query_key)) {
                let date = DateTime.fromISO(value.name).toISODate()
                if(date == null){
                    const parsedDate = parse(value.name, 'd MMM yyyy', new Date());
                    date =  format(parsedDate, 'yyyy-MM-dd');
                    if(filterValObj.from_created_at){
                        filterValObj.from_created_at.name=date.toString().substring(10, 0) 
                    }else{
                        filterValObj.from_due_date.name=date.toString().substring(10, 0)
                    }
                }
                Object.assign(filterValObj, { 
                    [`to_${column.field}`]: { 
                        id: date.ts, 
                        name: date.toString().substring(10, 0) ,
                        field_type: column.field_type 
                    }, 
                });
            }
            if (column.config && /* column.config.query_keys && */ column.config.filter_type === 'date') { // uncommment when you want to add customkeys for backend filters
                let [start, end] =  value.map(el => DateTime.fromJSDate(new Date(el)))
                Object.assign(filterValObj, { 
                    [column.config.query_keys['start']]: { 
                        id: start.ts, 
                        name: value[0] ? start.toString() : null,
                        field_type: column.field_type 
                    },
                })
                 Object.assign(filterValObj, { 
                    [column.config.query_keys['end']]: { 
                        id: end.ts, 
                        name: value[1] ? end.toString() : null,
                        field_type: column.field_type
                    }, 
                })
            }
            this.$emit("column-filter", {
                columnFilters: filterValObj,
            });
        },
        formateValue(val) {
            if (Array.isArray(val) && val.length) return val;
            else return [];
        },
        onWorkAllocation() {
            const rows = this.$refs.table.selectedRows;
            this.$emit("on-work-allocation", rows);
        },
        onCaseDelete() {
            const rows = this.$refs.table.selectedRows;
            this.$emit("on-case-delete", rows);
        },
        checkIfAllocationValueIsLarge(allocationValue) {
            if (allocationValue > 9) {
                return "9+";
            } else {
                return allocationValue;
            }
        },
        checkAllocation(props, type) {
            return props.row?.work_allocation?.[type] > 0;
        },
        onAllocation(props, type) {
            if (!this.checkAllocation(props, type)) {
                return;
            }
            this.$emit("on-allocation", { row: props.row, type });
        },
        getFilterValue(col) {
            // isFilters ? filters[column.field] : null
            if (this.isFilters) {
                return this.filters[col.field] ?? this.filters[col.query_key];
            } else return null;
        },
    },
   
};
</script>

<style lang="scss" scoped>
// @import "@/assets/mixins/mixins.scss";
@import "@shared/assets/mixins/mixins.scss";
@include tableComponent;
</style>
<style src="vue2-datepicker/index.css"></style>
<style lang="scss" scoped>
.w-full.mx-datepicker {
    width: 100px !important;
    max-width: 100%;
    min-width: 100%;
}
::v-deep {
    .mx-input {
        height: 2.25rem !important;
        border: none;
        border-radius: 0.5rem !important;
        box-shadow: none !important;
        -webkit-box-shadow: none !important;
        &::placeholder {
            @apply font-bold text-base-content opacity-80 text-base 
        }
    }
}
</style>

