import axios from "@/axios"

// Credit Allocation API's

export async function getCreditPacksAllocationList() {
    const url = "/client-credit-allocation/get-allocated-credit-pack-list"
    return await axios.get(url)
}

export async function getClientCreditPack(credit_pack_id) {
    const url = `/client-credit-allocation/get-credit-pack-details/${credit_pack_id}`
    return await axios.get(url)
}
export async function getUserAssigneCreditPackList(credit_pack_id) {
    const url = `/client-credit-allocation/get-user-list-assigned-to-credit-pack/${credit_pack_id}`
    return await axios.get(url)
}
export async function updatePack(payload) {
    const url = `/client-credit-allocation/update-credit-to-user`
    return await axios.put(url,payload)
}
export async function getUsers(client_id) {
    const url = `/user/client/${client_id}`
    return await axios.get(url)
}
export async function getCreditInventory() {
    const url = "client-credit-allocation/get-inventory-credit-pack-list"
    return await axios.get(url)
}
export async function createCreditInventory(payload) {
    const url = "client-credit-allocation/allocate-credit-to-user"
    return await axios.post(url, payload)
}
export async function deleteCredits(credit_pack_id, current_user_id) {
    const url = `client-credit-allocation/${credit_pack_id}/delete-user/${current_user_id}`
    return await axios.delete(url)
}

//Source List API's

export async function creditAndSourceList(params) {
    const url = `nrn?${params}`;
    return await axios.get(url);
}

// Source Usage API's

export async function getSourceList(client_id) {
    const url = `source-usage/${client_id}`
    return await axios.get(url)
}
export async function getCreditSourceList() {
    const url = `credit-source`
    return await axios.get(url)
}
export async function createUsage(payload) {
    const url = `source-usage`
    return await axios.post(url,payload)
}
export async function getUsageDetails(source_usage_id) {
    const url = `source-usage/details/${source_usage_id}`
    return await axios.get(url)
}
export async function updateUsageDetails(source_usage_id, payload) {
    const url = `source-usage/${source_usage_id}`
    return await axios.put(url, payload)
}

// Credit Pack Inventory API's

export async function getCreditsInventory(params, filterParams) {
    const url = `credit-pack-inventory/client/credit_pack_inventory?${params}`
    return await axios.get(url, {
        params: {
            ...filterParams
        }
    })
}

export async function getCreditsAlloted(credit_pack_id, params) {
    const url = `credit-pack-inventory/credit_pack_alloted?credit_pack_id=${credit_pack_id}&${params}`
    return await axios.get(url)
}

export async function getCreditsUsage(credit_pack_id, params) {
    const url = `credit-pack-inventory/credit_pack_usage?credit_pack_id=${credit_pack_id}&${params}`
    return await axios.get(url)
}
export async function activateCreditPack(credit_pack_id) {
    const url = `credit-pack-inventory/client/activate-credit-pack/${credit_pack_id}`
    return await axios.get(url)
}
export async function getQuery(query) {
    const url = `source-consume-callback/get_query_data?ref_id=${query}`
    return await axios.get(url)
}


 // Credit Pack API's
export async function getCreditPack(params) {
    const url = `client-credit-pack?${params}`
    return await axios.get(url)
}
export async function getCreditPackDeatails(base_credit_pack_id) {
    const url = `client-credit-pack/${base_credit_pack_id}`
    return await axios.get(url)
}

